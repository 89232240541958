export const blue = '#1C85E8'
export const blueOpaque = '#1C85E8B0'
export const darkBlue = '#0050AA'
export const marineBlue = '#284662'
export const lightBlue = '#D3E8F6'
export const lightBlueOpaque = '#D3E8F6B0'
export const semiDarkBlue = '#2F6193'
export const semiDarkBlueOpaque = '#2F6193B0'
export const lightGrey = '#f6fafd'
export const grey = '#7F92A3'
export const black = '#092C4C'
export const solitude = '#EBF7FF'
export const white = '#FFFFFF'
export const orange = '#FF8851'
export const orangeOpaque = '#FF8851B0'
export const semiDarkOrange = '#F7814A'
export const purple = '#888ED4'
export const purpleOpaque = '#888ED4B0'
export const aquamarine = '#3BC8E3'
export const semiLightBlue = '#009FE2'
export const brown = '#696877'
export const red = '#FF4D48'

export const mainTransientBackground = 'linear-gradient(69.67deg, #4371DD 31.79%, #92E7F2 97.12%)'
